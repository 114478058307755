import * as React from "react"
// components
import Footer from "../components/footer"

// styles
const pageStyles = {
  //color: "#F2F2F2",
  padding: 96,
  //backgroundColor: "#0f0c1e",
}
const greetingStyles = {
  fontFamily: "Montserrat, sans-serif",
  fontSize: 24,
  color: "#DDFF38",
  display: "block",
  marginBottom: 64,
}
const headingStyles = {
  fontFamily: "Montserrat, sans-serif",
  fontSize: 48,
  fontWeight: 100,
  lineHeight: "4.25rem",
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 640,
}
const accentStyles = {
  color: "#DDFF38",
  fontWeight: 500,
}
const headingSectionStyles = {
  display: "block",
  marginTop: 24
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <span style={greetingStyles}>hello.</span>
      <h1 style={headingStyles}>
        <span style={headingSectionStyles}>
          I'm Lorenzo Bruno,
          <br />
          a product designer based in London, focused on <span style={accentStyles}>user experience</span>, <span style={accentStyles}>front-end development</span> and <span style={accentStyles}>rapid prototyping</span>.
        </span> 
        <span style={headingSectionStyles}>I'm currently working at <span style={accentStyles}>Google</span>.</span>
      </h1>
      <Footer />
    </main>
  )
}

export default IndexPage

// page metadata
export const Head = () => (
  <>
    <title>Lorenzo Bruno | Product Designer &amp; Developer | London</title>
    <meta name="description" content="Lorenzo Bruno is a designer focused on user experience and fron-end development. Currently at Google" />
  </>
)
