import * as React from "react"

const year = new Date().getFullYear();

const linkStyle = {
  fontSize: 16,
  verticalAlign: "5%",
}
const listStyles = {
  listStyle: "none",
  paddingLeft: 0,
}
const listItemStyles = {  
  marginRight: 16,
  display: "inline",
}

const docLinks = [
  {
    text: "Drop me a line",
    url: "mailto:contact@lorenzobruno.com",
  },
  {
    text: "Linkedin",
    url: "https://uk.linkedin.com/in/lorenzobruno",
  }
]

const Footer = () => {
    return(
        <footer>
          <ul style={listStyles}>
            <li style={listItemStyles}>&copy;&nbsp;2022</li>
            {docLinks.map(doc => (
              <li key={doc.url} style={listItemStyles}>
                <a
                  style={linkStyle}
                  href={`${doc.url}?utm_source=starter&utm_medium=ts-docs&utm_campaign=minimal-starter-ts`}
                  target="_blank"
                >
                  {doc.text}
                </a>
              </li>
            ))}
          </ul>
        </footer>
    )
}

export default Footer